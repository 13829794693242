import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { AuthProvider, RequireAuth } from '@shared/components/AuthProvider/AuthProvider';
import { AnalyticsProvider } from '@shared/components/AnalyticsProvider/AnalyticsProvider';

import Home from '@/pages/Home/Home';

import Providers from '@/pages/Providers/Providers';
import ViewProvider from '@/pages/Providers/ViewProvider';
import EditProvider from '@/pages/Providers/EditProvider';
import Staff from '@/pages/Providers/Staff/Staff';
import EditStaffMember from '@/pages/Providers/Staff/EditStaffMember';
import EditContact from '@/pages/Providers/Staff/EditContact';

import Parents from '@/pages/Parents/Parents';
import ViewParent from '@/pages/Parents/ViewParent';

import Interests from '@/pages/Interests/Interests';
import EditInterest from '@/pages/Interests/EditInterest';

import SignIn from '@/pages/SignIn/SignIn';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword';
import ResetPassword from '@/pages/ResetPassword/ResetPassword';
import SignOut from '@/pages/SignOut/SignOut';

import config from '@shared/config';

if (config.sentry.enabled) {
	Sentry.init({
		dsn: config.sentry.dsn,
		integrations: [new BrowserTracing()],
		tracesSampleRate: config.sentry.tracesSampleRate,
		environment: config.sentry.environment,
	});
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<AuthProvider>
				<BrowserRouter>
					<AnalyticsProvider>
						<Routes>
							<Route path="/" element={<RequireAuth><Home /></RequireAuth>} />

							<Route path="/providers/" element={<RequireAuth><Providers /></RequireAuth>} />
							<Route path="/providers/:providerId/" element={<RequireAuth><ViewProvider /></RequireAuth>} />
							<Route path="/providers/add" element={<RequireAuth><EditProvider /></RequireAuth>} />
							<Route
								path="/providers/:providerId/edit"
								element={<RequireAuth><EditProvider /></RequireAuth>}
							/>
							<Route path="/providers/:providerId/staff/" element={<RequireAuth><Staff /></RequireAuth>} />
							<Route
								path="/providers/:providerId/staff/add"
								element={<RequireAuth><EditStaffMember /></RequireAuth>}
							/>
							<Route
								path="/providers/:providerId/staff/:memberId/edit"
								element={<RequireAuth><EditStaffMember /></RequireAuth>}
							/>
							<Route
								path="/providers/:providerId/contacts/add"
								element={<RequireAuth><EditContact /></RequireAuth>}
							/>
							<Route
								path="/providers/:providerId/contacts/:contactId/edit"
								element={<RequireAuth><EditContact /></RequireAuth>}
							/>

							<Route path="/parents/" element={<RequireAuth><Parents /></RequireAuth>} />
							<Route path="/parents/:parentId/" element={<RequireAuth><ViewParent /></RequireAuth>} />

							<Route path="/interests/" element={<RequireAuth><Interests /></RequireAuth>} />
							<Route path="/interests/add" element={<RequireAuth><EditInterest /></RequireAuth>} />
							<Route
								path="/interests/:interestId/edit"
								element={<RequireAuth><EditInterest /></RequireAuth>}
							/>

							<Route path="/sign-out" element={<RequireAuth><SignOut /></RequireAuth>} />

							<Route path="/sign-in" element={<SignIn />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/forgot-password/:token" element={<ResetPassword />} />
						</Routes>
					</AnalyticsProvider>
				</BrowserRouter>
			</AuthProvider>
		</HelmetProvider>
	</React.StrictMode>,
);
