import SideNav from '@shared/components/SideNav/SideNav';

export default function AdminSideNav() {
	const sections = [
		{
			routes: [
				{ to: '/', title: 'Home' },
				{ to: '/providers/', title: 'Providers' },
				{ to: '/parents/', title: 'Parents' },
				{ to: '/interests/', title: 'Interests' },
				{ to: '/sign-out', title: 'Sign out' },
			],
		},
	];
	return <SideNav sections={sections} />;
}
