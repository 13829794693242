import { useState, useEffect } from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PhoneInputHack from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { titleCase } from 'title-case';

import Alert from '@shared/components/Alert/Alert';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './EditContact.module.css';

// Workaround for https://github.com/vitejs/vite/issues/2139
const PhoneInput = PhoneInputHack.default ? PhoneInputHack.default : PhoneInputHack;

const schema = yup.object({
	name: yup.string().required('Please enter a name'),
	email: yup.string().email().required('Please enter an email address'),
	phone: yup.string().required('Please enter a phone number'),
}).required();

const blankImageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

function EditContact() {
	const [contact, setContact] = useState();

	const params = useParams();
	const navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState();
	const { control, register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
	});

	async function onSubmit(data) {
		try {
			const payload = { ...contact, ...data };
			let response;
			if (payload.id) {
				response = await api.put(`/backoffice/providers/${params.providerId}/contacts/${params.contactId}`, { json: payload });
			} else {
				response = await api.post(`/backoffice/providers/${params.providerId}/contacts/add`, { json: payload });
			}
			setErrorMessage();
			navigate(`/providers/${params.providerId}/staff/`);
		} catch (error) {
			setErrorMessage(error.message);
		}
	}

	useEffect(() => {
		if (params.contactId === undefined) {
			setContact({
				name: '',
				email: '',
				phone: '',
				primary: false,
			});
			return;
		}

		async function fetchContact() {
			try {
				const response = await api.get(`/backoffice/providers/${params.providerId}/contacts/${params.contactId}`);
				const json = await response.json();
				setContact(json);
			} catch (error) {
				setErrorMessage(error.message);
			}
		}

		fetchContact();
	}, [params.providerId, params.contactId]);

	const action = params.contactId === undefined ? 'add' : 'edit';

	return (
		<div className={styles.editContactPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § {titleCase(action)} Contact</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{titleCase(action)} Contact</h1>
				</header>
				<div className="card">
					<div className={styles.contact}>
						{contact ? (
							<form onSubmit={handleSubmit(onSubmit)} noValidate>

								<div className={'field ' + (errors.name ? 'invalid' : '')}>
									<label htmlFor="name">Name</label>
									<input
										id="name"
										name="name"
										type="text"
										{...register('name', { required: true })}
										required
										defaultValue={contact?.name ?? ''}
										aria-invalid={Boolean(errors.name)}
									/>
									<ErrorMessage as="p" className="error" errors={errors} name="name" />
								</div>

								<div className={'field ' + (errors.email ? 'invalid' : '')}>
									<label htmlFor="email">Email</label>
									<input
										id="email"
										name="email"
										type="email"
										{...register('email', { required: true })}
										required
										defaultValue={contact?.email ?? ''}
										aria-invalid={Boolean(errors.email)}
									/>
									<ErrorMessage as="p" className="error" errors={errors} name="email" />
								</div>

								<div className={'field ' + (errors.phone ? 'invalid' : '')}>
									<label htmlFor="phone">Phone</label>
									<Controller
										control={control}
										name="phone"
										defaultValue={contact?.phone ?? ''}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<PhoneInput
												country="hk"
												preferredCountries={['hk', 'sg']}
												inputProps={{
													id: 'phone',
													name: 'phone',
													autoComplete: 'tel',
													required: true,
													'aria-invalid': Boolean(errors.phone),
												}}
												placeholder=""
												value={contact?.phone ?? ''}
												aria-invalid={Boolean(errors.phone)}
												onChange={onChange}
												onBlur={onBlur}
												inputRef={ref}
											/>
										)}
									/>
									<ErrorMessage as="p" className="error" errors={errors} name="phone" />
								</div>

								<div className={'checkbox field ' + (errors.primary ? 'invalid' : '')}>
									<input
										id="primary"
										name="primary"
										type="checkbox"
										{...register('primary')}
										defaultChecked={contact?.primary ?? false}
									/>
									<label htmlFor="primary">Primary</label>
									<ErrorMessage as="p" className="error" errors={errors} name="primary" />
								</div>

								{errorMessage && (
									<Alert type="error" message={errorMessage} />
								)}

								<div className={styles.actions}>
									<Link className="button cancel" to={`/providers/${params.providerId}/staff/`}>Cancel</Link>
									<button className="primary" type="submit">{action === 'add' ? 'Add contact' : 'Save contact'}</button>
								</div>
							</form>
						) : (
							<p className="loading">
								Loading… <img src="/images/spinner-dark.svg" alt="" />
							</p>
						)}
					</div>
				</div>
			</main>
		</div>
	);
}

export default EditContact;
