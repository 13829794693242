import config from '@shared/config';

export class AuthenticationError extends Error {
}

export class UnauthorizedError extends Error {
	constructor(name, message) {
		super(message);
		this.name = name;
	}
}

class Api {
	constructor(baseUrl) {
		this.baseUrl = baseUrl;
	}

	static getAuthToken() {
		return sessionStorage?.getItem('authToken');
	}

	async get(url, params) {
		return this.doRequest(url, { ...params, method: 'GET' });
	}

	async post(url, params) {
		return this.doRequest(url, { ...params, method: 'POST' });
	}

	async put(url, params) {
		return this.doRequest(url, { ...params, method: 'PUT' });
	}

	async delete(url, params) {
		return this.doRequest(url, { ...params, method: 'DELETE' });
	}

	async doRequest(url, {
		method = 'GET',
		headers = {},
		body = null,
		json = null,
		authenticate = true,
	} = {}) {
		const absoluteUrl = new URL(url, this.baseUrl);

		const actualHeaders = { ...headers };
		if (authenticate) {
			const token = Api.getAuthToken();
			if (!token) throw new AuthenticationError('Attempted to make an authenticated API request without a token.');
			actualHeaders.authorization = `Bearer ${token}`;
		}

		let actualBody = body;
		if (json) {
			actualBody = JSON.stringify(json);
			if (!actualHeaders['content-type']) {
				actualHeaders['content-type'] = 'application/json';
			}
		}

		const response = await fetch(absoluteUrl, {
			method,
			headers: actualHeaders,
			body: actualBody,
		});
		if (!response.ok) {
			let parsedError;
			try {
				parsedError = await response.json();
			} catch {
				throw new Error('Unknown error.');
			}
			switch (response.status) {
				case 401:
					throw new UnauthorizedError(parsedError.title, parsedError.detail);
				default: {
					const error = new Error(parsedError.detail);
					error.name = parsedError.title;
					throw error;
				}
			}
		}
		return response;
	}
}

const api = new Api(config.activkidz.apiBaseUrl);

export default api;
