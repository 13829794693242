function parseBoolean(string) {
	if (string === undefined) return undefined;
	return Boolean(JSON.parse(string?.toLowerCase()));
}

function safeParseFloat(string) {
	if (string === undefined) return undefined;
	return parseFloat(string);
}

const config = {
	activkidz: {
		apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
		assetsUrl: import.meta.env.VITE_S3_ASSETS_URL,
		loginPath: import.meta.env.VITE_LOGIN_PATH,
	},
	sentry: {
		enabled: parseBoolean(import.meta.env.VITE_SENTRY_ENABLED),
		dsn: import.meta.env.VITE_SENTRY_DSN,
		tracesSampleRate: safeParseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
	},
	googleAnalytics: {
		enabled: parseBoolean(import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLED),
		measurementId: import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID,
	},
	googleMaps: {
		apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
	},
};

export default config;
