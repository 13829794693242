import dayjs from 'dayjs';

export function formatDate(date, { relative = true, includeWeekday = true, includeYear = false } = {}) {
	const parsed = dayjs(date, 'YYYY-MM-DD');
	const today = dayjs();
	if (relative && parsed.isSame(today, 'date')) return 'Today';
	if (relative && parsed.isSame(today.add(1, 'day'), 'date')) return 'Tomorrow';
    const components = [
        includeWeekday ? 'dddd,' : '',
        'D',
        'MMMM',
        includeYear ? 'YYYY' : '',
    ]
	return parsed.format(components.join(' '));
}
