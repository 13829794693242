import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './Interests.module.css';

function Interests() {
	const [interests, setInterests] = useState();
	const [page, setPage] = useState(1);
	const [pageSize] = useState(20);
	const [interestCount, setInterestCount] = useState();
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({});
	const [forceUpdate, setForceUpdate] = useState();

	useEffect(() => {
		async function fetchInterests() {
			try {
				const filterParams = Object.entries(filters).map(([key, value]) => `${key}=${value}`);
				const response = await api.get(`/backoffice/interests/?page=${page}&pageSize=${pageSize}&search=${search}&${filterParams}`);
				const json = await response.json();
				setInterests(json.items);
				setInterestCount(json.total);
			} catch (error) {
				console.error(error);
				setInterests();
				setInterestCount();
			}
		}

		fetchInterests();
	}, [page, pageSize, search, filters, forceUpdate]);

	function searchTermChanged(event) {
		setSearch(event.target.value);
	}

	function goBack() {
		if (page === 1) {
			console.error('Attempted to go back from page 1.');
			return;
		}
		setPage(page - 1);
	}

	function goForward() {
		if (page * pageSize >= interestCount) {
			console.error('Attempted to go forward from last page.');
			return;
		}
		setPage(page + 1);
	}

	function onChangeFilter(event, filter) {
		const newFilters = { ...filters };
		newFilters[filter] = event.target.value;
		setFilters(newFilters);
	}

	async function deleteInterest(interest) {
		try {
			await api.delete(`/backoffice/interests/${interest.id}`);
			setForceUpdate(new Date());
		} catch (error) {
			console.error(error.message);
		}
	}

	const countRange = `${((page - 1) * pageSize + 1)}–${(page - 1) * pageSize + (interests?.length ?? 0)}`;

	return (
		<div className={styles.interestsPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § Interests</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>Interests</h1>
					<Link to="/interests/add" className="button primary">Add interest</Link>
				</header>
				<div className="card">
					<div className={styles.filters}>
						<input type="search" className={styles.search} onChange={(event) => searchTermChanged(event)} />
						<select onChange={(event) => onChangeFilter(event, 'featured')}>
							<option value="all">Featured and secondary</option>
							<option value="featured">Featured</option>
							<option value="secondary">Secondary</option>
						</select>
					</div>
					<div className={styles.interests}>
						{interests ? (
							<div>
								{interests.length > 0 ? (
									<>
										<table>
											<thead>
												<tr>
													<th scope="col">Name</th>
													<th scope="col">Color</th>
													<th scope="col">Featured</th>
													<th scope="col" className={styles.actions}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{interests.map((interest) => (
													<tr key={interest.id}>
														<td>{interest.name}</td>
														<td className={styles.swatchCell}>
															<div className="swatch" style={{ backgroundColor: interest.color }} />
														</td>
														<td className={styles.featuredCell}>
															{interest.featured && (
																<img src="/images/star-filled.svg" alt="Yes" />
															)}
														</td>
														<td className={styles.actions}>
															<Link to={`/interests/${interest.id}/edit`}><img src="/images/edit.svg" alt="Edit" /></Link>
															<button type="button" className="icon" onClick={() => deleteInterest(interest)}>
																<img src="/images/delete.svg" alt="Delete" />
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className={styles.pagination}>
											<p className={styles.count}>
												Showing {countRange} of {interestCount} {interestCount === 1 ? 'interest' : 'interests'}.
											</p>
											{(page > 1 || page * pageSize < interestCount) && (
												<div className="buttons">
													<button type="button" className="previous" disabled={page === 1} onClick={() => goBack()}>
														<img src="/images/arrow-left.svg" alt="Previous page" />
													</button>
													<button type="button" className="next" disabled={page * pageSize >= interestCount} onClick={() => goForward()}>
														<img src="/images/arrow-right.svg" alt="Next page" />
													</button>
												</div>
											)}
										</div>
									</>
								) : (
									<p className={styles.count}>
										No interests found.
									</p>
								)}
							</div>
						) : (
							<p className="loading">
								Loading… <img src="/images/spinner-dark.svg" alt="" />
							</p>
						)}
					</div>
				</div>
			</main>
		</div>
	);
}

export default Interests;
