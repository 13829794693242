import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import config from '@shared/config';

import api from '@shared/api/api';

const AuthContext = createContext();

export function AuthProvider({ children }) {
	function getItem(name) {
		return sessionStorage?.getItem(name);
	}

	const [token, setToken] = useState(getItem('authToken'));
	const signIn = useCallback(async (role, email, password, rememberMe) => {
		const response = await api.post(config.activkidz.loginPath, {
			json: {
				email,
				password,
				rememberMe,
			},
			authenticate: false,
		});
		const { token: tokenInfo } = await response.json();
		setToken(tokenInfo.value);
		sessionStorage.setItem('authToken', tokenInfo.value);
	}, []);

	const signOut = useCallback(() => {
		sessionStorage.removeItem('authToken');
		setToken();
	}, []);

	const forgotPassword = useCallback(async (email) => {
		await api.post('/auth/forgot', {
			json: {
				email,
			},
			authenticate: false,
		});
	}, []);
	const resetPassword = useCallback(async (email, password, confirmPassword, resetToken) => {
		const response = await api.post('/auth/reset', {
			json: {
				email,
				password,
				confirmPassword,
				token: resetToken,
			},
			authenticate: false,
		});
		const { token: tokenInfo } = await response.json();
		setToken(tokenInfo.value);
		sessionStorage.setItem('authToken', tokenInfo.value);
	}, []);

	const value = useMemo(() => ({
		token,
		signIn,
		signOut,
		forgotPassword,
		resetPassword,
	}), [token, signIn, signOut, forgotPassword, resetPassword]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
	return useContext(AuthContext);
}

export function RequireAuth({ children }) {
	const auth = useAuth();
	const location = useLocation();

	if (!auth.token) {
		return <Navigate to="/sign-in" state={{ from: location }} />;
	}

	return children;
}
