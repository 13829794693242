import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './ViewProvider.module.css';

function ViewProvider() {
	const [provider, setProvider] = useState();

	const params = useParams();

	useEffect(() => {
		async function fetchProvider() {
			try {
				const response = await api.get(`/backoffice/providers/${params.providerId}`);
				const json = await response.json();
				setProvider(json);
			} catch (error) {
				console.error(error);
				setProvider();
			}
		}

		fetchProvider();
	}, [params.providerId]);

	return (
		<div className={styles.viewParentPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § Providers § {provider ? provider.name : 'Loading…'}</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{provider?.name ?? ''}</h1>
					<Link to={`/providers/${params.providerId}/edit`} className="button">Edit</Link>
				</header>
				{provider ? (
					<>
						<div className="card">
							<table>
								<tbody>
									<tr>
										<th scope="row">Type</th>
										<td>{provider.type.name}</td>
									</tr>
									<tr>
										<th scope="row">Email</th>
										<td>{provider.email}</td>
									</tr>
									<tr>
										<th scope="row">Mobile</th>
										<td>{provider.mobile}</td>
									</tr>
									<tr>
										<th scope="row">Work phone</th>
										<td>{provider.workPhone}</td>
									</tr>
									<tr>
										<th scope="row">Website</th>
										<td><a rol="noopener" href={provider.website}>{provider.website}</a></td>
									</tr>
								</tbody>
							</table>
						</div>
						{/* <h2>Bank details</h2> */}
						{/* <div className="card"> */}
						{/*	<table> */}
						{/*		<tbody> */}
						{/*			<tr> */}
						{/*				<th scope="row">Bank name</th> */}
						{/*				<td>{provider.bankName}</td> */}
						{/*			</tr> */}
						{/*			<tr> */}
						{/*				<th scope="row">Account name</th> */}
						{/*				<td>{provider.bankAccountName}</td> */}
						{/*			</tr> */}
						{/*			<tr> */}
						{/*				<th scope="row">Account number</th> */}
						{/*				<td>{provider.bankAccountNumber}</td> */}
						{/*			</tr> */}
						{/*			<tr> */}
						{/*				<th scope="row">Bank code</th> */}
						{/*				<td>{provider.bankCode}</td> */}
						{/*			</tr> */}
						{/*			<tr> */}
						{/*				<th scope="row">Branch code</th> */}
						{/*				<td>{provider.bankBranchCode}</td> */}
						{/*			</tr> */}
						{/*		</tbody> */}
						{/*	</table> */}
						{/* </div> */}
					</>
				) : (
					<div className="card">
						<p className="loading">
							Loading… <img src="/images/spinner-dark.svg" alt="" />
						</p>
					</div>
				)}
			</main>
		</div>
	);
}

export default ViewProvider;
