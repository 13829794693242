import { useState, useEffect } from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PhoneInputHack from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { titleCase } from 'title-case';

import Alert from '@shared/components/Alert/Alert';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './EditStaffMember.module.css';

// Workaround for https://github.com/vitejs/vite/issues/2139
const PhoneInput = PhoneInputHack.default ? PhoneInputHack.default : PhoneInputHack;

const schema = yup.object({
	firstName: yup.string().required('Please enter a first name'),
	lastName: yup.string().required('Please enter a last name'),
	email: yup.string().email().required('Please enter an email address'),
	role: yup.string().required('Please select the staff role'),
}).required();

const blankImageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

function EditStaffMember() {
	const [member, setMember] = useState();

	const params = useParams();
	const navigate = useNavigate();

	const [errorMessage, setErrorMessage] = useState();
	const { control, register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (params.memberId === undefined) {
			setMember({
				firstName: '',
				lastName: '',
				email: '',
				role: '',
				sendInvite: true,
			});
			return;
		}

		async function fetchMember() {
			try {
				const response = await api.get(`/backoffice/providers/${params.providerId}/staff/${params.memberId}`);
				const json = await response.json();
				setMember(json);
			} catch (error) {
				setErrorMessage(error.message);
			}
		}

		fetchMember();
	}, [params.providerId, params.memberId]);

	async function onSubmit(data) {
		try {
			const payload = { ...member, ...data };
			let response;
			if (payload.id) {
				response = await api.put(`/backoffice/providers/${params.providerId}/staff/${params.memberId}`, { json: payload });
			} else {
				response = await api.post(`/backoffice/providers/${params.providerId}/staff/add`, { json: payload });
			}
			setErrorMessage();
			navigate(`/providers/${params.providerId}/staff/`);
		} catch (error) {
			setErrorMessage(error.message);
		}
	}

	const action = params.memberId === undefined ? 'add' : 'edit';

	return (
		<div className={styles.editStaffMemberPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § {titleCase(action)} Staff Member</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{titleCase(action)} Staff Member</h1>
				</header>
				<div className="card">
					<div className={styles.staffMember}>
						{member ? (
							<form onSubmit={handleSubmit(onSubmit)} noValidate>

								<div className="row">
									<div className={'field ' + (errors.firstName ? 'invalid' : '')}>
										<label htmlFor="firstName">First name</label>
										<input
											id="firstName"
											name="firstName"
											type="text"
											{...register('firstName', { required: true })}
											required
											defaultValue={member?.firstName ?? ''}
											aria-invalid={Boolean(errors.firstName)}
										/>
										<ErrorMessage as="p" className="error" errors={errors} name="firstName" />
									</div>

									<div className={'field ' + (errors.lastName ? 'invalid' : '')}>
										<label htmlFor="lastName">Last name</label>
										<input
											id="lastName"
											name="lastName"
											type="text"
											{...register('lastName', { required: true })}
											required
											defaultValue={member?.lastName ?? ''}
											aria-invalid={Boolean(errors.lastName)}
										/>
										<ErrorMessage as="p" className="error" errors={errors} name="lastName" />
									</div>
								</div>

								<div className="row">
									<div className={'field ' + (errors.email ? 'invalid' : '')}>
										<label htmlFor="email">Email</label>
										<input
											id="email"
											name="email"
											type="email"
											{...register('email', { required: true })}
											required
											defaultValue={member?.email ?? ''}
											aria-invalid={Boolean(errors.email)}
										/>
										<ErrorMessage as="p" className="error" errors={errors} name="email" />
									</div>

									<div className={'field ' + (errors.role ? 'invalid' : '')}>
										<label htmlFor="role">Role</label>
										<select
											{...register('role', { required: true })}
											defaultValue={member?.role ?? ''}
											aria-invalid={Boolean(errors.role)}
										>
											<option value="">Please select…</option>
											<option value="owner">Owner</option>
											<option value="manager">Manager</option>
											<option value="teacher">Teacher</option>
											<option value="reservations">Reservations</option>
										</select>
										<ErrorMessage as="p" className="error" errors={errors} name="role" />
									</div>
								</div>

								{typeof params.memberId === 'undefined' && (
									<div className={'checkbox field ' + (errors.sendInvite ? 'invalid' : '')}>
										<input
											id="sendInvite"
											name="sendInvite"
											type="checkbox"
											{...register('sendInvite')}
											defaultChecked={member?.sendInvite ?? true}
										/>
										<label htmlFor="sendInvite">Send email invitation</label>
										<ErrorMessage as="p" className="error" errors={errors} name="sendInvite" />
									</div>
								)}

								{errorMessage && (
									<Alert type="error" message={errorMessage} />
								)}

								<div className={styles.actions}>
									<Link className="button cancel" to={`/providers/${params.providerId}/staff/`}>Cancel</Link>
									<button className="primary" type="submit">{action === 'add' ? 'Add staff member' : 'Save staff member'}</button>
								</div>
							</form>
						) : (
							<p className="loading">
								Loading… <img src="/images/spinner-dark.svg" alt="" />
							</p>
						)}
					</div>
				</div>
			</main>
		</div>
	);
}

export default EditStaffMember;
