import { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import ActualOutsideClickHandler from 'react-outside-click-handler';
import dialogPolyfill from 'dialog-polyfill';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';
import Alert from '@shared/components/Alert/Alert';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './Providers.module.css';

/*
 * OutsideClickHandler breaks minified builds completely, so we just make it a no-op in
 * that mode for now. It’s unmaintained, so we should replace it entirely at some point.
 */
function NullComponent(props) {
	return props.children;
}

const OutsideClickHandler = import.meta.env.MODE === 'production' ? NullComponent : ActualOutsideClickHandler;

function ImportDialog({
	open = false,
	title,
	errorMessage,
	onSubmit,
	onClose = () => undefined,
}) {
	const dialogRef = useRef();
	useEffect(() => {
		if (dialogRef.current) dialogPolyfill.registerDialog(dialogRef.current);
	}, [dialogRef]);

	const schema = yup.object({
		file: yup.mixed().test('fileSize', 'Please select a file', (value) => value.length > 0),
	});
	const {
		register,
		control,
		handleSubmit,
		getValues,
		setValue,
		trigger,
		formState: { errors, isSubmitting },
	} = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (open && !dialogRef.current?.open) {
			const scrollPosition = document.documentElement.scrollTop;
			dialogRef.current.showModal();
			document.body.style.overflow = 'hidden';
			window.scrollTo({
				top: scrollPosition,
				behaviour: 'instant',
			});
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [open]);

	if (!open) return null;

	function handleOnClose() {
		onClose();
	}

	return (
		<dialog className={styles.importDialog} onClose={handleOnClose} ref={dialogRef}>
			<header>
				<h3>{title ?? ' Import'}</h3>
				<form method="dialog">
					<button type="submit">
						<img src="/images/icon-close.svg" alt="Close" />
					</button>
				</form>
			</header>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<fieldset disabled={isSubmitting}>
					<div className={'field ' + (errors.file ? 'invalid' : '')}>
						<label htmlFor="name">CSV file</label>
						<input
							id="file"
							name="file"
							type="file"
							{...register('file', { required: true })}
							required
							aria-invalid={Boolean(errors.file)}
						/>
						<ErrorMessage as="p" className="error" errors={errors} name="file" />
					</div>

					{errorMessage && <Alert type="error" message={errorMessage} />}

					<div className="field">
						<button
							type="submit"
							className={'primary button ' + (isSubmitting ? 'loading' : '')}
						>
							{isSubmitting ? 'Saving…' : 'Save'}
						</button>
					</div>

				</fieldset>
			</form>
		</dialog>
	);
}

function Providers() {
	const [providers, setProviders] = useState();
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [providerCount, setProviderCount] = useState();
	const [search, setSearch] = useState('');
	const [importClassesForProvider, setImportClassesForProvider] = useState();
	const [importStudentsForProvider, setImportStudentsForProvider] = useState();

	useEffect(() => {
		async function fetchProviders() {
			try {
				const response = await api.get(`backoffice/providers/?page=${page}&pageSize=${pageSize}&search=${search}`);
				const json = await response.json();
				setProviders(json.items);
				setProviderCount(json.total);
			} catch (error) {
				console.error(error);
				setProviders();
				setProviderCount();
			}
		}

		fetchProviders();
	}, [page, pageSize, search]);

	function searchTermChanged(event) {
		setSearch(event.target.value);
	}

	function openImportClassesDialog(provider) {
		setImportClassesForProvider(provider);
	}

	function openImportStudentsDialog(provider) {
		setImportStudentsForProvider(provider);
	}

	function closeImportDialog() {
		setImportClassesForProvider();
		setImportStudentsForProvider();
	}

	function goBack() {
		if (page === 1) {
			console.error('Attempted to go back from page 1.');
			return;
		}
		setPage(page - 1);
	}

	function goForward() {
		if (page * pageSize >= providerCount) {
			console.error('Attempted to go forward from last page.');
			return;
		}
		setPage(page + 1);
	}

	async function importFile(values) {
		let url;
		if (importStudentsForProvider) {
			url = `/v2/provider/${importStudentsForProvider.id}/kids/import`;
		} else if (importClassesForProvider) {
			url = `/v2/provider/${importClassesForProvider.id}/kids/import`;
		} else {
			console.error('Unknown import attempt.');
		}
		try {
			const response = await api.post(url, {
				body: values.file[0],
			});
		} catch (error) {
			console.error(error);
		}
		closeImportDialog();
	}

	const countRange = `${((page - 1) * pageSize + 1)}–${(page - 1) * pageSize + (providers?.length ?? 0)}`;

	return (
		<div className={styles.providersPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § Providers</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>Providers</h1>
					<Link to="/providers/add" className="button">Add provider</Link>
				</header>
				<div className="card">
					<div className={styles.filters}>
						<input type="search" className={styles.search} onChange={(event) => searchTermChanged(event)} />
					</div>
					<div className={styles.providers}>
						{providers ? (
							<div>
								{providers.length > 0 ? (
									<>
										<table>
											<thead>
												<tr>
													<th scope="col">Name</th>
													<th scope="col">Email</th>
													<th scope="col">Type</th>
													<th scope="col" className={styles.actions}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{providers.map((provider) => (
													<tr key={provider.id}>
														<td>{provider.name}</td>
														<td>{provider.email}</td>
														<td>{provider.type.name}</td>
														<td className={styles.actions}>
															<Link
																to={`/providers/${provider.id}/`}
																title="View provider"
															>
																<img src="/images/view.svg" alt="View" />
															</Link>
															<Link
																to={`/providers/${provider.id}/edit`}
																title="Edit provider"
															>
																<img src="/images/edit.svg" alt="Edit" />
															</Link>
															<button
																type="button"
																title="Import classes"
																className="icon"
																onClick={() => openImportClassesDialog(provider)}
															>
																<img src="/images/book-open.svg" alt="Import classes" />
															</button>
															<button
																type="button"
																title="Import students"
																className="icon"
																onClick={() => openImportStudentsDialog(provider)}
															>
																<img src="/images/user-plus.svg" alt="Import students" />
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className={styles.pagination}>
											<p className={styles.count}>
												Showing {countRange} of {providerCount} {providerCount === 1 ? 'provider' : 'providers'}.
											</p>
											{(page > 1 || page * pageSize < providerCount) && (
												<div className="buttons">
													<button
														type="button"
														className="previous"
														disabled={page === 1}
														onClick={(event) => goBack()}
													>
														<img src="/images/arrow-left.svg" alt="Previous page" />
													</button>
													<button
														type="button"
														className="next"
														disabled={page * pageSize >= providerCount}
														onClick={(event) => goForward()}
													>
														<img src="/images/arrow-right.svg" alt="Next page" />
													</button>
												</div>
											)}
										</div>
									</>
								) : (
									<p className={styles.count}>
										No providers found.
									</p>
								)}
							</div>
						) : (
							<p className="loading">
								Loading… <img src="/images/spinner-dark.svg" alt="" />
							</p>
						)}
					</div>
				</div>
				<ImportDialog
					open={Boolean(importClassesForProvider || importStudentsForProvider)}
					onSubmit={importFile}
					onClose={() => closeImportDialog()}
					title={importClassesForProvider ? 'Import classes' : 'Import students'}
				/>
			</main>
		</div>
	);
}

export default Providers;
