import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from '@shared/components/AuthProvider/AuthProvider';
import Alert from '@shared/components/Alert/Alert';

import '@/css/index.css';
import styles from './SignIn.module.css';

const schema = yup.object({
	email: yup.string()
		.email('This is not a valid email address')
		.required('Please enter your email address'),
	password: yup.string().required('Please enter your password'),
}).required();

export default function SignIn() {
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuth();

	const next = location.state?.from?.pathname || '/';

	const [errorMessage, setErrorMessage] = useState();
	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
			password: '',
			rememberMe: false,
		},
	});

	async function onSubmit(data) {
		try {
			await auth.signIn('admin', data.email, data.password, false);
			setErrorMessage();
			navigate(next, { replace: true });
		} catch (error) {
			setErrorMessage(error.message);
		}
	}

	return (
		<div className={styles.signInPage + ' singleCardPage'}>
			<Helmet>
				<title>ActivKidz Admin § Sign In</title>
			</Helmet>
			<main>

				<header>
					<h1>Sign In</h1>
				</header>

				<hr />

				{errorMessage && (
					<Alert type="error" message={errorMessage} />
				)}

				<form onSubmit={handleSubmit(onSubmit)} noValidate>

					<div className={'field ' + (errors.email && 'invalid')}>
						<label htmlFor="email">Email address</label>
						<input
							id="email"
							name="email"
							type="email"
							{...register('email', { required: true })}
							autoComplete="email"
							required
							aria-invalid={Boolean(errors.email)}
						/>
						<ErrorMessage as="p" className="error" errors={errors} name="email" />
					</div>

					<div className={'field ' + (errors.password && 'invalid')}>
						<label htmlFor="password">Password</label>
						<input
							id="password"
							name="password"
							type="password"
							{...register('password', { required: true })}
							autoComplete="current-password"
							required
						/>
						<ErrorMessage as="p" className="error" errors={errors} name="password" />
					</div>

					{/* <div className="field checkbox"> */}
					{/*	<input */}
					{/*		id="rememberMe" */}
					{/*		name="rememberMe" */}
					{/*		type="checkbox" */}
					{/*		{...register('rememberMe')} */}
					{/*	/> */}
					{/*	<label htmlFor="rememberMe">Remember me</label> */}
					{/* </div> */}

					<div className="field">
						<button
							type="submit"
							className="chevron"
							disabled={isSubmitting}
						>
							Sign In
						</button>
					</div>

				</form>

			</main>
		</div>
	);
}
