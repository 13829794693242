import { createContext, useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import config from '@shared/config';

const AnalyticsContext = createContext();

export function AnalyticsProvider({ children }) {
	const location = useLocation();

	useEffect(() => {
		if (config.googleAnalytics.enabled && window.gtag) window.gtag('config', config.googleAnalytics.measurementId);
	}, [location]);

	function event({ action, params }) {
		if (config.googleAnalytics.enabled && window.gtag) window.gtag('event', action, params);
	}

	const value = useMemo(() => ({
		event,
	}));

	return (
		<AnalyticsContext.Provider value={value}>
			<GoogleAnalyticsScript />
			{children}
		</AnalyticsContext.Provider>
	);
}

export function useAnalytics() {
	return useContext(AnalyticsContext);
}

export function GoogleAnalyticsScript() {
	if (!config.googleAnalytics.enabled || !config.googleAnalytics.measurementId) return null;

	if (!document.querySelector('head script[id="ga"]')) {
		const gaScript = document.createElement('script');
		gaScript.id = 'ga';
		gaScript.async = true;
		gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.googleAnalytics.measurementId;
		document.head.appendChild(gaScript);
	}

	if (!document.querySelector('head script[id="ga-config"]')) {
		const gaConfigScript = document.createElement('script');
		gaConfigScript.id = 'ga-config';
		gaConfigScript.innerHTML = `
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				dataLayer.push(arguments);
			}
			gtag('js', new Date());

			gtag('config', '${config.googleAnalytics.measurementId}', {
				send_page_view: false,
			});
		`;
		document.head.appendChild(gaConfigScript);
	}

	return null;
}
