import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from '@shared/components/AuthProvider/AuthProvider';
import Alert from '@shared/components/Alert/Alert';

import '@/css/index.css';
import styles from './ForgotPassword.module.css';

const schema = yup.object({
	email: yup.string()
		.email('This is not a valid email address')
		.required('Please enter your email address'),
}).required();

export default function ForgotPassword() {
	const auth = useAuth();

	const [requestedEmail, setRequestedEmail] = useState();
	const [errorMessage, setErrorMessage] = useState();
	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
		},
	});

	async function onSubmit(data) {
		try {
			await auth.forgotPassword(data.email);
			setErrorMessage();
			setRequestedEmail(data.email);
		} catch (error) {
			setErrorMessage(error.message);
			setRequestedEmail();
		}
	}

	return (
		<div className={styles.forgotPasswordPage + ' singleCardPage'}>
			<Helmet>
				<title>ActivKidz § Forgot Password</title>
			</Helmet>
			<main>

				<header>
					<h1>Forgot Password</h1>

					<nav>
						<Link to="/sign-in">
							Sign In
						</Link>
					</nav>
				</header>

				<hr />

				{requestedEmail ? (
					<Alert
						type="info"
						message={`If we find an account for ${requestedEmail}, we will send an email with instructions to reset the password.`}
					/>
				) : (
					<>
						<p>
							Forgot your password? Enter your e-mail address below.
						</p>

						{errorMessage && (
							<Alert type="error" message={errorMessage} />
						)}

						<form onSubmit={handleSubmit(onSubmit)} noValidate>

							<div className={'field ' + (errors.email && 'invalid')}>
								<label htmlFor="email">Email address</label>
								<input
									id="email"
									name="email"
									type="email"
									{...register('email', { required: true })}
									autoComplete="email"
									required
									aria-invalid={Boolean(errors.email)}
								/>
								<ErrorMessage as="p" className="error" errors={errors} name="email" />
							</div>

							<div className="field">
								<button
									type="submit"
									className="chevron"
									disabled={isSubmitting}
								>
									Reset Password
								</button>
							</div>

						</form>
					</>
				)}

			</main>
		</div>
	);
}
