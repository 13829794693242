import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { titleCase } from 'title-case';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';
import api from '@shared/api/api';
import { formatDate } from '@shared/lib/formatters';
import '@/css/index.css';
import styles from './Parents.module.css';
import SortLabel from '../../../components/SortLabel';

function Parents() {
	const [parents, setParents] = useState();
	const [page, setPage] = useState(1);
	// TODO: Page count should be reduced to 20 once we support sorting on the backend
	const [pageSize] = useState(200);
	const [parentCount, setParentCount] = useState();
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({});

	useEffect(() => {
		async function fetchParents() {
			try {
				const filterParams = Object.entries(filters).map(([key, value]) => `${key}=${value}`);
				const response = await api.get(`/backoffice/parents/?page=${page}&pageSize=${pageSize}&search=${search}&${filterParams}`);
				const json = await response.json();
				setParents(json.items);
				setParentCount(json.total);
			} catch (error) {
				console.error(error);
				setParents();
				setParentCount();
			}
		}

		fetchParents();
	}, [page, pageSize, search, filters]);

	function searchTermChanged(event) {
		setSearch(event.target.value);
	}

	function goBack() {
		if (page === 1) {
			console.error('Attempted to go back from page 1.');
			return;
		}
		setPage(page - 1);
	}

	function goForward() {
		if (page * pageSize >= parentCount) {
			console.error('Attempted to go forward from last page.');
			return;
		}
		setPage(page + 1);
	}

	function onChangeFilter(event, filter) {
		const newFilters = { ...filters };
		newFilters[filter] = event.target.value;
		setFilters(newFilters);
	}

	async function disableParent(parent) {
		const response = await api.post(`/backoffice/parents/${parent.id}/disable`);
		const json = await response.json();
		setParents(parents.map((currentParent) => (currentParent.id === json.id ? json : currentParent)));
	}

	async function enableParent(parent) {
		const response = await api.post(`/backoffice/parents/${parent.id}/enable`);
		const json = await response.json();
		setParents(parents.map((currentParent) => (currentParent.id === json.id ? json : currentParent)));
	}

	const countRange = `${((page - 1) * pageSize + 1)}–${(page - 1) * pageSize + (parents?.length ?? 0)}`;
	const handleSortByJoinDateDesc = () => {
		const tmp = parents.sort((a, b) => (a.created > b.created ? -1 : 1));
		setParents([...tmp]);
	};
	const handleSortByJoinDateASC = () => {
		const tmp = parents.sort((a, b) => (a.created > b.created ? 1 : -1));
		setParents([...tmp]);
	};
	return (
		<div className={styles.parentsPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § Parents</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>Parents</h1>
				</header>
				<div className="card">
					<div className={styles.filters}>
						<input type="search" className={styles.search} onChange={(event) => searchTermChanged(event)} />
						<select onChange={(event) => onChangeFilter(event, 'status')}>
							<option value="all">All statuses</option>
							<option value="active">Active</option>
							<option value="pending">Pending</option>
							<option value="disabled">Disabled</option>
						</select>
					</div>
					<div className={styles.parents}>
						{parents ? (
							<div>
								{parents.length > 0 ? (
									<>
										<table>
											<thead>
												<tr>
													<th scope="col" className={styles.name}>Name</th>
													<th scope="col" className={styles.email}>Email</th>
													<th scope="col" className={styles.phone}>Phone</th>
													<th scope="col" className={styles.signedUp}>
														<SortLabel
															onSortDesc={handleSortByJoinDateDesc}
															onSortAsc={handleSortByJoinDateASC}
														>
															Signed up
														</SortLabel>
													</th>
													<th scope="col" className={styles.status}>Status</th>
													<th scope="col" className={styles.actions}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{parents.map((parent) => (
													<tr key={parent.id}>
														<td>{parent.firstName + ' ' + parent.lastName}</td>
														<td>{parent.user.email}</td>
														<td>{parent.mobile}</td>
														<td>{formatDate(parent.created, {
															includeWeekday: false,
															includeYear: true,
														})}
														</td>
														<td>
															<span
																className={`statusChip ${parent.user.status}`}
															>
																{titleCase(parent.user.status)}
															</span>
														</td>
														<td className={styles.actions}>
															<Link to={`/parents/${parent.id}/`}><img
																src="/images/view.svg"
																alt="View"
															/>
															</Link>
															{parent.user.status === 'active' ? (
																<button
																	type="button" className="icon"
																	onClick={() => disableParent(parent)}
																>
																	<img src="/images/slash.svg" alt="Disable" />
																</button>
															) : (
																<button
																	type="button" className="icon"
																	onClick={() => enableParent(parent)}
																>
																	<img src="/images/check.svg" alt="Enable" />
																</button>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
										<div className={styles.pagination}>
											<p className={styles.count}>
												Showing {countRange} of {parentCount} {parentCount === 1 ? 'parent' : 'parents'}.
											</p>
											{(page > 1 || page * pageSize < parentCount) && (
												<div className="buttons">
													<button
														type="button" className="previous" disabled={page === 1}
														onClick={() => goBack()}
													>
														<img src="/images/arrow-left.svg" alt="Previous page" />
													</button>
													<button
														type="button" className="next"
														disabled={page * pageSize >= parentCount}
														onClick={() => goForward()}
													>
														<img src="/images/arrow-right.svg" alt="Next page" />
													</button>
												</div>
											)}
										</div>
									</>
								) : (
									<p className={styles.count}>
										No parents found.
									</p>
								)}
							</div>
						) : (
							<p className="loading">
								Loading… <img src="/images/spinner-dark.svg" alt="" />
							</p>
						)}
					</div>
				</div>
			</main>
		</div>
	);
}

export default Parents;
