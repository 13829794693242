import { useState, useEffect, useRef } from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SketchPicker } from 'react-color';

import { titleCase } from 'title-case';

import Alert from '@shared/components/Alert/Alert';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './EditInterest.module.css';

const schema = yup.object({
	name: yup.string().required('Please enter a name'),
	color: yup.string().when('featured', {
		is: true,
		then: yup.string().required('Featured interests need a color'),
	}),
	icon: yup.mixed().when('featured', {
		is: true,
		then: yup.mixed().test('fileSize', 'Featured interests need an icon', (value) => (typeof value === 'string') && Boolean(value)),
	}),
}).required();

const blankImageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

function EditInterest() {
	const [interest, setInterest] = useState();
	const [colorPickerIsOpen, setColorPickerIsOpen] = useState(false);
	const [hasIconPreview, setHasIconPreview] = useState();

	const params = useParams();
	const navigate = useNavigate();

	const colorPopoverRef = useRef();
	const iconPreviewRef = useRef();

	const [errorMessage, setErrorMessage] = useState();
	const { register, setValue, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
	});

	async function onSubmit(data) {
		try {
			const payload = { ...interest, ...data };
			delete payload.iconUrl;
			if (typeof payload.icon === 'object') delete payload.icon;
			let response;
			if (payload.id) {
				response = await api.put(`/backoffice/interests/${params.interestId}`, { json: payload });
			} else {
				response = await api.post('/backoffice/interests/add', { json: payload });
			}
			setErrorMessage();
			navigate('/interests/');
		} catch (error) {
			setErrorMessage(error.message);
		}
	}

	useEffect(() => {
		if (params.interestId === undefined) {
			setInterest({
				name: '',
				color: '',
				icon: undefined,
				iconUrl: undefined,
			});
			return;
		}

		async function fetchInterest() {
			try {
				const response = await api.get(`/backoffice/interests/${params.interestId}`);
				const json = await response.json();
				setInterest(json);
				if (json.iconUrl) {
					setValue('icon', 'no-change');
				} else {
					setValue('icon', undefined);
				}
				setHasIconPreview(Boolean(json.iconUrl));
			} catch (error) {
				setErrorMessage(error.message);
			}
		}

		fetchInterest();
	}, [params.interestId, setValue]);

	function toggleColorPicker() {
		setColorPickerIsOpen(!colorPickerIsOpen);
	}

	function onColorPickerChange(color) {
		setValue('color', color.hex);
		setInterest({ ...interest, color: color.hex });
	}

	function dismissColorPicker(event) {
		if (event.target !== colorPopoverRef.current) return;
		setColorPickerIsOpen(false);
	}

	function onColorInputChange(event) {
		setValue('color', event.target.value);
		setInterest({ ...interest, color: event.target.value });
	}

	function onIconChange(event) {
		const dataReader = new FileReader();
		dataReader.onloadend = () => {
			iconPreviewRef.current.src = dataReader.result;
			setHasIconPreview(true);
		};
		dataReader.readAsDataURL(event.target.files[0]);
		const textReader = new FileReader();
		textReader.onloadend = () => {
			setValue('icon', textReader.result);
		};
		textReader.readAsText(event.target.files[0]);
	}

	function clearIcon(event) {
		const fileInput = event.target.closest('.iconPreview').querySelector('input[type=file]');
		fileInput.value = null;
		setValue('iconUrl', null);
		setValue('icon', null);
		setHasIconPreview(false);
		iconPreviewRef.current.src = blankImageUrl;
	}

	const action = params.interestId === undefined ? 'add' : 'edit';

	return (
		<div className={styles.editInterestPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § {titleCase(action)} Interest</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{titleCase(action)} Interest</h1>
				</header>
				<div className="card">
					<div className={styles.interests}>
						{interest ? (
							<form onSubmit={handleSubmit(onSubmit)} noValidate>

								<div className={'field ' + (errors.name ? 'invalid' : '')}>
									<label htmlFor="name">Name</label>
									<input
										id="name"
										name="name"
										type="text"
										{...register('name', { required: true })}
										required
										defaultValue={interest?.name ?? ''}
										aria-invalid={Boolean(errors.name)}
									/>
									<ErrorMessage as="p" className="error" errors={errors} name="name" />
								</div>

								<div className={'checkbox field ' + (errors.featured ? 'invalid' : '')}>
									<input
										id="featured"
										name="featured"
										type="checkbox"
										{...register('featured')}
										defaultChecked={interest?.featured ?? false}
									/>
									<label htmlFor="featured">Featured</label>
									<ErrorMessage as="p" className="error" errors={errors} name="featured" />
								</div>

								<div className={'field ' + (errors.color ? 'invalid' : '')}>
									<label htmlFor="color">Color</label>
									<div className="colorField">
										<input
											id="color"
											name="color"
											type="text"
											{...register('color', { required: false })}
											defaultValue={interest?.color ?? ''}
											onChange={(event) => onColorInputChange(event)}
										/>
										<button type="button" className="icon" onClick={() => toggleColorPicker()}>
											<div className="swatch" style={{ backgroundColor: interest.color }} />
										</button>
										<div
											className={'popover ' + (colorPickerIsOpen ? 'open' : 'closed')}
											ref={colorPopoverRef}
											onClick={(event) => dismissColorPicker(event)}
										>
											<SketchPicker
												color={interest.color ?? ''}
												onChange={(color) => onColorPickerChange(color)}
												disableAlpha
											/>
										</div>
									</div>
									<ErrorMessage as="p" className="error" errors={errors} name="color" />
								</div>

								<div className={'icon field ' + (errors.icon ? 'invalid' : '')}>
									<label htmlFor="icon">
										Icon
										{interest.iconUrl ? (
											<img
												ref={iconPreviewRef}
												className="preview"
												style={{ backgroundColor: interest?.color ?? '#202e78' }}
												src={interest.iconUrl}
												alt=""
											/>
										) : (
											<img
												ref={iconPreviewRef}
												className="preview"
												style={{ backgroundColor: interest?.color ?? '#202e78' }}
												src={blankImageUrl}
												alt=""
											/>
										)}
									</label>
									<div className="iconPreview">
										<input
											id="icon"
											name="icon"
											type="file"
											accept="image/svg+xml,.svg"
											{...register('icon', { required: false })}
											onChange={(event) => onIconChange(event)}
										/>
										{hasIconPreview && (
											<button type="button" className="icon clear" onClick={(event) => clearIcon(event)}>
												<img src="/images/x.svg" alt="Clear" />
											</button>
										)}
									</div>
									<ErrorMessage as="p" className="error" errors={errors} name="icon" />
								</div>

								{errorMessage && (
									<Alert type="error" message={errorMessage} />
								)}

								<div className={styles.actions}>
									<Link className="button cancel" to="/interests/">Cancel</Link>
									<button className="primary" type="submit">{action === 'add' ? 'Add interest' : 'Save interest'}</button>
								</div>
							</form>
						) : (
							<p className="loading">
								Loading… <img src="/images/spinner-dark.svg" alt="" />
							</p>
						)}
					</div>
				</div>
			</main>
		</div>
	);
}

export default EditInterest;
