
import { Fragment } from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';

import '@/css/index.css';
import styles from './SideNav.module.css';

function NavLink({ children, to, ...props }) {
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<Link
			className={match && styles.active}
			to={to}
			{...props}
		>
			{children}
		</Link>
	);
}

export default function SideNav({ sections }) {
	return (
		<nav className={styles.sideNav}>
			{sections.map((section, sectionIndex) => (
				<Fragment key={sectionIndex.toString() + section.title}>
					{section.title && (<h2>{section.title}</h2>)}
					<ul>
						{section.routes.map((route, routeIndex) => (
							<li key={routeIndex.toString() + route.to}><NavLink to={route.to}>{route.title}</NavLink></li>
						))}
					</ul>
				</Fragment>
			))}
		</nav>
	);
}
