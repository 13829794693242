import { useState, useEffect } from 'react';

import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { titleCase } from 'title-case';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './Staff.module.css';

function Staff() {
	const [provider, setProvider] = useState();
	const [forceUpdate, setForceUpdate] = useState();

	const params = useParams();

	useEffect(() => {
		async function fetchProvider() {
			try {
				const response = await api.get(`/backoffice/providers/${params.providerId}`);
				const json = await response.json();
				setProvider(json);
			} catch (error) {
				console.error(error);
				setProvider();
			}
		}

		fetchProvider();
	}, [params.providerId, forceUpdate]);

	async function deleteStaffMember(staffMember) {
		try {
			await api.delete(`/backoffice/providers/${params.providerId}/staff/${staffMember.id}`);
			setForceUpdate(new Date());
		} catch (error) {
			console.error(error.message);
		}
	}

	async function deleteContact(contact) {
		try {
			await api.delete(`/backoffice/providers/${params.providerId}/contacts/${contact.id}`);
			setForceUpdate(new Date());
		} catch (error) {
			console.error(error.message);
		}
	}

	return (
		<div className={styles.staffPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § {provider?.name ?? 'Provider'} Staff</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{provider?.name ?? 'Provider'} staff</h1>
					<div className="actions">
						<Link to={`/providers/${params.providerId}/staff/add`} className="button">Add staff member</Link>
						<Link to={`/providers/${params.providerId}/contacts/add`} className="button">Add contact</Link>
					</div>
				</header>
				{provider ? (
					<>
						<h2>Staff members</h2>
						<div className="card">
							<div className={styles.staff}>
								<div>
									{provider.staff.length > 0 ? (
										<table>
											<thead>
												<tr>
													<th scope="col">First name</th>
													<th scope="col">Last name</th>
													<th scope="col">Email</th>
													<th scope="col">Role</th>
													<th scope="col" className={styles.actions}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{provider.staff.map((staffMember) => (
													<tr key={staffMember.id}>
														<td>{staffMember.firstName}</td>
														<td>{staffMember.lastName}</td>
														<td>{staffMember.email}</td>
														<td>{titleCase(staffMember.role)}</td>
														<td className={styles.actions}>
															<Link to={`/providers/${provider.id}/staff/${staffMember.id}/edit`}>
																<img src="/images/edit.svg" alt="Edit" />
															</Link>
															<button type="button" className="icon" onClick={() => deleteStaffMember(staffMember)}>
																<img src="/images/delete.svg" alt="Delete" />
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<p className={styles.count}>
											No staff found.
										</p>
									)}
								</div>
							</div>
						</div>
						<h2>Contacts</h2>
						<div className="card">
							<div className={styles.contacts}>
								<div>
									{provider.contacts.length > 0 ? (
										<table>
											<thead>
												<tr>
													<th scope="col">Name</th>
													<th scope="col">Email</th>
													<th scope="col">Phone</th>
													<th scope="col">Primary</th>
													<th scope="col" className={styles.actions}>Actions</th>
												</tr>
											</thead>
											<tbody>
												{provider.contacts.map((contact) => (
													<tr key={contact.id}>
														<td>{contact.name}</td>
														<td>{contact.email}</td>
														<td>{contact.phone}</td>
														<td className={styles.featuredCell}>
															{contact.primary && (
																<img src="/images/star-filled.svg" alt="Yes" />
															)}
														</td>
														<td className={styles.actions}>
															<Link to={`/providers/${provider.id}/contacts/${contact.id}/edit`}>
																<img src="/images/edit.svg" alt="Edit" />
															</Link>
															<button type="button" className="icon" onClick={() => deleteContact(contact)}>
																<img src="/images/delete.svg" alt="Delete" />
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<p className={styles.count}>
											No staff found.
										</p>
									)}
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="card">
						<p className="loading">
							Loading… <img src="/images/spinner-dark.svg" alt="" />
						</p>
					</div>
				)}
			</main>
		</div>
	);
}

export default Staff;
