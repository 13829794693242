import { useEffect, useRef, useState } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import PhoneInputHack from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { titleCase } from 'title-case';
import reduceImage from 'image-blob-reduce';

import Alert from '@shared/components/Alert/Alert';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import api from '@shared/api/api';

import '@/css/index.css';
import styles from './EditProvider.module.css';

// Workaround for https://github.com/vitejs/vite/issues/2139
const PhoneInput = PhoneInputHack.default ? PhoneInputHack.default : PhoneInputHack;

const schema = yup.object({
	name: yup.string().required('Please enter a name'),
	type: yup.number().typeError('Please select the provider type'),
	workPhone: yup.string().required('Please enter a phone number'),
	mobile: yup.string().required('Please enter a phone number'),
	email: yup.string()
		.email('This is not a valid email address')
		.required('Please enter an email address'),
	website: yup.string().url('Please enter a valid URL'),
	percentPerTransaction: yup.number()
		.test('zeroOrGreater', 'Please enter a positive number', (value) => value >= 0)
		.typeError('Please enter the ActivKidz fee'),
}).required();

const blankImageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

function EditProvider() {
	const [provider, setProvider] = useState();
	const [providerTypes, setProviderTypes] = useState();
	const [hasImagePreview, setHasImagePreview] = useState();

	const params = useParams();
	const navigate = useNavigate();
	const imagePreviewRef = useRef();

	const [errorMessage, setErrorMessage] = useState();
	const { control, register, setValue, clearErrors, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (params.providerId === undefined) {
			setProvider({
				name: '',
				type: '',
				mobile: '',
				workPhone: '',
				bookingType: '',
			});
			return;
		}

		async function fetchProvider() {
			try {
				const response = await api.get(`/backoffice/providers/${params.providerId}`);
				const json = await response.json();
				setProvider({ ...json, feesType: 'variable' });
				setHasImagePreview(Boolean(json.logo));
			} catch (error) {
				setErrorMessage(error.message);
			}
		}

		fetchProvider();
	}, [params.providerId, setValue]);

	useEffect(() => {
		async function fetchProviderTypes() {
			try {
				const response = await api.get('/backoffice/provider-types/');
				const json = await response.json();
				setProviderTypes(json.items);
			} catch (error) {
				setErrorMessage(error.message);
			}
		}

		fetchProviderTypes();
	}, []);

	// function onChangeProviderBookingType(event) {
	// 	setValue('bookingType', event.target.value);
	// 	setProvider({ ...provider, bookingType: event.target.value });
	// 	if (event.target.value) clearErrors('bookingType');
	// }

	function onChangeFeesType(event) {
		// setValue('feesType', event.target.value);
		setValue('feesType', 'variable');
		setProvider({ ...provider, feesType: 'variable' });
		if (event.target.value) clearErrors('feesType');
	}

	function onImageChange(event) {
		setValue('logo', null);
		const dataReader = new FileReader();
		dataReader.onloadend = () => {
			imagePreviewRef.current.src = dataReader.result;
			setHasImagePreview(true);
			setValue('image', event.target.files[0]);
		};
		dataReader.readAsDataURL(event.target.files[0]);
	}

	function clearImage(event) {
		const fileInput = event.target.closest('.imagePreview').querySelector('input[type=file]');
		fileInput.value = null;
		setValue('logo', null);
		setValue('image', null);
		setHasImagePreview(false);
		imagePreviewRef.current.src = blankImageUrl;
	}

	async function onSubmit(data) {
		try {
			const hasImage = !(!data.image || data.image.length === 0);
			let imageUUID = null;

			if (hasImage) {
				const response = await api.get('/backoffice/providers/image-upload-details');
				const json = await response.json();
				imageUUID = json.uuid;

				const imageData = await reduceImage().toBlob(data.image, { max: 1000 });

				await fetch(json.url, {
					body: imageData,
					method: 'PUT',
					headers: {
						'x-amz-acl': 'public-read',
					},
				});
			}
			// Fixing the booking, and fees type
			const payload = { ...provider, ...data, imageUUID, bookingType: 'reservationsOnly', feesType: 'variable' };
			if (payload.id) {
				await api.put(`/backoffice/providers/${params.providerId}`, { json: payload });
			} else {
				await api.post('/backoffice/providers/add', { json: payload });
			}
			setErrorMessage();
			navigate('/providers/');
		} catch (error) {
			setErrorMessage(error.message ?? 'Unknown error');
		}
	}

	const action = params.providerId === undefined ? 'add' : 'edit';

	return (
		<div className={styles.editProviderPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § {titleCase(action)} Provider</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{titleCase(action)} Provider</h1>
					{provider?.id && (
						<div className="actions">
							<Link to={`/providers/${provider.id}/staff/`} className="button">Manage staff</Link>
						</div>
					)}
				</header>

				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<div className={styles.provider}>
						{provider && providerTypes ? (
							<>
								<h2>Details</h2>
								<div className="card">
									<div className="row">

										<div className={'field ' + (errors.name ? 'invalid' : '')}>
											<label htmlFor="name">Organisation name</label>
											<input
												id="name"
												name="name"
												type="text"
												{...register('name', { required: true })}
												required
												defaultValue={provider?.name ?? ''}
												aria-invalid={Boolean(errors.name)}
											/>
											<ErrorMessage as="p" className="error" errors={errors} name="name" />
										</div>

										<div className={'field ' + (errors.type ? 'invalid' : '')}>
											<label htmlFor="type">Provider type</label>
											<select
												{...register('type', { required: true })}
												defaultValue={provider?.type?.id ?? ''}
												aria-invalid={Boolean(errors.type)}
											>
												<option value="">Please select…</option>
												{providerTypes.map((providerType) => (
													<option
														key={providerType.id}
														value={providerType.id}
													>{providerType.name}
													</option>
												))}
											</select>
											<ErrorMessage as="p" className="error" errors={errors} name="type" />
										</div>

									</div>

									<div className="row">

										<div className={'field ' + (errors.mobile ? 'invalid' : '')}>
											<label htmlFor="mobile">Mobile</label>
											<Controller
												control={control}
												name="mobile"
												defaultValue={provider?.mobile ?? ''}
												render={({ field: { onChange, onBlur, value, ref } }) => (
													<PhoneInput
														country="hk"
														preferredCountries={['hk', 'sg']}
														inputProps={{
															id: 'mobile',
															name: 'mobile',
															autoComplete: 'tel',
															required: true,
															'aria-invalid': Boolean(errors.mobile),
														}}
														placeholder=""
														value={provider?.mobile ?? ''}
														aria-invalid={Boolean(errors.mobile)}
														onChange={onChange}
														onBlur={onBlur}
														inputRef={ref}
													/>
												)}
											/>
											<ErrorMessage as="p" className="error" errors={errors} name="mobile" />
										</div>

										<div className={'field ' + (errors.workPhone ? 'invalid' : '')}>
											<label htmlFor="workPhone">Work phone</label>
											<Controller
												control={control}
												name="workPhone"
												defaultValue={provider?.workPhone ?? ''}
												render={({ field: { onChange, onBlur, value, ref } }) => (
													<PhoneInput
														country="hk"
														preferredCountries={['hk', 'sg']}
														inputProps={{
															id: 'workPhone',
															name: 'workPhone',
															autoComplete: 'tel',
															required: true,
															'aria-invalid': Boolean(errors.workPhone),
														}}
														placeholder=""
														value={provider?.workPhone ?? ''}
														aria-invalid={Boolean(errors.workPhone)}
														onChange={onChange}
														onBlur={onBlur}
														inputRef={ref}
													/>
												)}
											/>
											<ErrorMessage as="p" className="error" errors={errors} name="workPhone" />
										</div>

									</div>

									<div className="row">

										<div className={'field ' + (errors.email ? 'invalid' : '')}>
											<label htmlFor="email">Email</label>
											<input
												id="email"
												name="email"
												type="email"
												{...register('email', { required: true })}
												required
												defaultValue={provider?.email ?? ''}
												aria-invalid={Boolean(errors.email)}
											/>
											<ErrorMessage as="p" className="error" errors={errors} name="email" />
										</div>

										<div className={'field ' + (errors.website ? 'invalid' : '')}>
											<label htmlFor="website">Website</label>
											<input
												id="website"
												name="website"
												type="url"
												{...register('website', { required: false })}
												defaultValue={provider?.website ?? ''}
												aria-invalid={Boolean(errors.website)}
											/>
											<ErrorMessage as="p" className="error" errors={errors} name="website" />
										</div>

									</div>

									<div className={'field ' + (errors.about ? 'invalid' : '')}>
										<label htmlFor="about">About</label>
										<textarea
											id="about"
											name="about"
											{...register('about', { required: false })}
											defaultValue={provider?.about ?? ''}
											aria-invalid={Boolean(errors.about)}
										/>
										<ErrorMessage as="p" className="error" errors={errors} name="about" />
									</div>
								</div>

								<h2>Settings</h2>

								<div className="card settings">

									<div className="row">

										<div className={'checkbox field ' + (errors.active ? 'invalid' : '')}>
											<input
												id="active"
												name="active"
												type="checkbox"
												{...register('active')}
												defaultChecked={provider?.active ?? true}
											/>
											<label htmlFor="active">Active</label>
											<ErrorMessage as="p" className="error" errors={errors} name="active" />
										</div>

										{/* <div className={'field ' + (errors.bookingType ? 'invalid' : '')}> */}
										{/*	<label htmlFor="bookingType">Bookings</label> */}
										{/*	<select */}
										{/*		{...register('bookingType', { required: true })} */}
										{/*		onChange={(event) => onChangeProviderBookingType(event)} */}
										{/*		defaultValue={provider?.bookingType ?? ''} */}
										{/*		aria-invalid={Boolean(errors.bookingType)} */}
										{/*	> */}
										{/*		<option value="">Please select…</option> */}
										{/*		<option value="marketingOnly">Marketing only</option> */}
										{/*		<option value="standardBookings">Standard bookings</option> */}
										{/*		<option value="reservationsOnly">Reservations only</option> */}
										{/*	</select> */}
										{/*	<ErrorMessage as="p" className="error" errors={errors} name="bookingType" /> */}
										{/* </div> */}

									</div>
								</div>

								<h2>Fee settings</h2>

								{/* <div */}
								{/*	className={'card feeSettings ' + (['', undefined, 'marketingOnly'].includes(provider.bookingType) ? 'disabled' : '')} */}
								{/* > */}

								<div className="row">

									<div className={'field ' + (errors.feesType ? 'invalid' : '')}>
										<label htmlFor="feesType">Type</label>
										<select
											{...register('feesType', { required: true })}
											onChange={(event) => onChangeFeesType(event)}
											defaultValue={provider?.feesType ?? 'variable'}
											aria-invalid={Boolean(errors.feesType)}
										>
											<option value="variable">Variable</option>
										</select>
										<ErrorMessage as="p" className="error" errors={errors} name="feesType" />
									</div>

									{/* {provider.feesType === 'fixed' && ( */}
									{/*	<div className={'field ' + (errors.chargePerTransaction ? 'invalid' : '')}> */}
									{/*		<label htmlFor="chargePerTransaction">Charge per transaction</label> */}
									{/*		<div className="currency hk"> */}
									{/*			<input */}
									{/*				id="chargePerTransaction" */}
									{/*				name="chargePerTransaction" */}
									{/*				type="text" */}
									{/*				inputMode="numeric" pattern="[0-9.]*" */}
									{/*				{...register('chargePerTransaction', { required: false })} */}
									{/*				defaultValue={provider?.chargePerTransaction ?? ''} */}
									{/*				aria-invalid={Boolean(errors.chargePerTransaction)} */}
									{/*				disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
									{/*			/> */}
									{/*		</div> */}
									{/*		<ErrorMessage */}
									{/*			as="p" className="error" errors={errors} */}
									{/*			name="chargePerTransaction" */}
									{/*		/> */}
									{/*	</div> */}
									{/* )} */}

									<div className={'field ' + (errors.percentPerTransaction ? 'invalid' : '')}>
										<label htmlFor="percentPerTransaction">Charge per transaction</label>
										<div className="percent">
											<input
												id="percentPerTransaction"
												name="percentPerTransaction"
												type="text"
												inputMode="numeric" pattern="[0-9.]*"
												{...register('percentPerTransaction', { required: true })}
												defaultValue={provider?.percentPerTransaction ?? '15'}
												aria-invalid={Boolean(errors.percentPerTransaction)}
											/>
										</div>
										<ErrorMessage
											as="p" className="error" errors={errors}
											name="percentPerTransaction"
										/>
									</div>

									{/* <div className={'checkbox field ' + (errors.passthrough ? 'invalid' : '')}> */}
									{/*	<input */}
									{/*		id="passthrough" */}
									{/*		name="passthrough" */}
									{/*		type="checkbox" */}
									{/*		{...register('passthrough', { value: provider?.passthrough ?? false })} */}
									{/*		disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
									{/*	/> */}
									{/*	<label htmlFor="passthrough">Passthrough</label> */}
									{/*	<ErrorMessage as="p" className="error" errors={errors} name="passthrough" /> */}
									{/* </div> */}

								</div>

								{/* </div> */}

								{/* <h2>Bank details</h2> */}

								{/* <div */}
								{/*	className={'card ' + (['', undefined, 'marketingOnly'].includes(provider.bookingType) ? 'disabled' : '')} */}
								{/* > */}

								{/*	<div className="row"> */}

								{/*		<div className={'field ' + (errors.bankAccountName ? 'invalid' : '')}> */}
								{/*			<label htmlFor="bankAccountName">Account name</label> */}
								{/*			<input */}
								{/*				id="bankAccountName" */}
								{/*				name="bankAccountName" */}
								{/*				type="text" */}
								{/*				{...register('bankAccountName', { required: false })} */}
								{/*				defaultValue={provider?.bankAccountName ?? ''} */}
								{/*				aria-invalid={Boolean(errors.bankAccountName)} */}
								{/*				disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
								{/*			/> */}
								{/*			<ErrorMessage */}
								{/*				as="p" className="error" errors={errors} */}
								{/*				name="bankAccountName" */}
								{/*			/> */}
								{/*		</div> */}

								{/*		<div className={'field ' + (errors.bankAccountNumber ? 'invalid' : '')}> */}
								{/*			<label htmlFor="bankAccountNumber">Account number</label> */}
								{/*			<input */}
								{/*				id="bankAccountNumber" */}
								{/*				name="bankAccountNumber" */}
								{/*				type="text" */}
								{/*				{...register('bankAccountNumber', { required: false })} */}
								{/*				defaultValue={provider?.bankAccountNumber ?? ''} */}
								{/*				aria-invalid={Boolean(errors.bankAccountNumber)} */}
								{/*				disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
								{/*			/> */}
								{/*			<ErrorMessage */}
								{/*				as="p" className="error" errors={errors} */}
								{/*				name="bankAccountNumber" */}
								{/*			/> */}
								{/*		</div> */}

								{/*	</div> */}

								{/*	<div className="row"> */}

								{/*		<div className={'field ' + (errors.bankName ? 'invalid' : '')}> */}
								{/*			<label htmlFor="bankName">Bank name</label> */}
								{/*			<input */}
								{/*				id="bankName" */}
								{/*				name="bankName" */}
								{/*				type="text" */}
								{/*				{...register('bankName', { required: false })} */}
								{/*				defaultValue={provider?.bankName ?? ''} */}
								{/*				aria-invalid={Boolean(errors.bankName)} */}
								{/*				disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
								{/*			/> */}
								{/*			<ErrorMessage as="p" className="error" errors={errors} name="bankName" /> */}
								{/*		</div> */}

								{/*		<div className={'field ' + (errors.bankCode ? 'invalid' : '')}> */}
								{/*			<label htmlFor="bankCode">Bank code</label> */}
								{/*			<input */}
								{/*				id="bankCode" */}
								{/*				name="bankCode" */}
								{/*				type="text" */}
								{/*				{...register('bankCode', { required: false })} */}
								{/*				defaultValue={provider?.bankCode ?? ''} */}
								{/*				aria-invalid={Boolean(errors.bankCode)} */}
								{/*				disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
								{/*			/> */}
								{/*			<ErrorMessage as="p" className="error" errors={errors} name="bankCode" /> */}
								{/*		</div> */}

								{/*		<div className={'field ' + (errors.bankBranchCode ? 'invalid' : '')}> */}
								{/*			<label htmlFor="bankBranchCode">Branch code</label> */}
								{/*			<input */}
								{/*				id="bankBranchCode" */}
								{/*				name="bankBranchCode" */}
								{/*				type="text" */}
								{/*				{...register('bankBranchCode', { required: false })} */}
								{/*				defaultValue={provider?.bankBranchCode ?? ''} */}
								{/*				aria-invalid={Boolean(errors.bankBranchCode)} */}
								{/*				disabled={['', undefined, 'marketingOnly'].includes(provider.bookingType)} */}
								{/*			/> */}
								{/*			<ErrorMessage */}
								{/*				as="p" className="error" errors={errors} */}
								{/*				name="bankBranchCode" */}
								{/*			/> */}
								{/*		</div> */}

								{/*	</div> */}

								{/* </div> */}

								<h2>Media</h2>
								<div className="card">
									<div className={'image field ' + (errors.icon ? 'invalid' : '')}>
										<label htmlFor="image">
											Image
											{provider.logo ? (
												<img
													ref={imagePreviewRef}
													className="preview"
													src={provider.logo}
													alt=""
												/>
											) : (
												<img
													ref={imagePreviewRef}
													className="preview"
													src={blankImageUrl}
													alt=""
												/>
											)}
										</label>
										<div className="imagePreview">
											<input
												id="image"
												name="image"
												type="file"
												accept="image/jpeg,.jpeg,.jpg,image/png,.png"
												{...register('image', { required: false })}
												onChange={(event) => onImageChange(event)}
											/>
											{hasImagePreview && (
												<button
													type="button" className="icon clear"
													onClick={(event) => clearImage(event)}
												>
													<img src="/images/x.svg" alt="Clear" />
												</button>
											)}
										</div>
										<ErrorMessage as="p" className="error" errors={errors} name="image" />
									</div>
								</div>

								<div className="card">
									{errorMessage && (
										<Alert type="error" message={errorMessage} />
									)}

									<div className={styles.actions}>
										<Link className="button cancel" to="/provider/">Cancel</Link>
										<button
											className="primary"
											type="submit"
										>{action === 'add' ? 'Add provider' : 'Save provider'}
										</button>
									</div>
								</div>
							</>
						) : (
							<div className="card">
								{errorMessage ? (
									<Alert type="error" message={errorMessage} />
								) : (
									<p className="loading">
										Loading… <img src="/images/spinner-dark.svg" alt="" />
									</p>
								)}
							</div>
						)}
					</div>
				</form>
			</main>
		</div>
	);
}

export default EditProvider;
