import React from 'react';

import styles from './Alert.module.css';

function Alert({ message, type }) {
	if (!message) return null;

	return (
		<div role="alert" className={styles.alert + ' ' + styles[type]}>
			<img src={`/images/${type}.svg`} alt="" />
			<p>
				{message}
			</p>
		</div>
	);
}

export default Alert;
