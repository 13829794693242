import { useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAuth } from '@shared/components/AuthProvider/AuthProvider';
import Alert from '@shared/components/Alert/Alert';

import '@/css/index.css';
import styles from './ResetPassword.module.css';

const schema = yup.object({
	email: yup.string()
		.email('This is not a valid email address')
		.required('Please enter your email address'),
	password: yup.string().matches(
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_\-$%^&*])(?=.{8,})/,
		'Password must contain eight characters, one uppercase, one lowercase, one digit, and one special case character',
	).required('Please enter a password'),
	confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
}).required();

export default function ResetPassword() {
	const auth = useAuth();
	const navigate = useNavigate();
	const params = useParams();

	const [errorMessage, setErrorMessage] = useState();
	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			email: '',
		},
	});

	async function onSubmit(data) {
		try {
			await auth.resetPassword(data.email, data.password, data.confirmPassword, params.token);
			setErrorMessage();
			navigate('/', { replace: true });
		} catch (error) {
			setErrorMessage(error.message);
		}
	}

	return (
		<div className={styles.cardContainer}>
			<Helmet>
				<title>ActivKidz § Reset Password</title>
			</Helmet>
			<div className={styles.card}>

				<header>
					<h1>Reset Password</h1>

					<p className={styles.headerLink}>
						<Link to="/sign-in">
							Sign In
						</Link>
					</p>
				</header>

				<hr />

				<p>
					Enter your new password below.
				</p>

				{errorMessage && (
					<Alert type="error" message={errorMessage} />
				)}

				<form onSubmit={handleSubmit(onSubmit)} noValidate>

					<div className={'field ' + (errors.email && 'invalid')}>
						<label htmlFor="email">Email address</label>
						<input
							id="email"
							name="email"
							type="email"
							{...register('email', { required: true })}
							autoComplete="email"
							required
							aria-invalid={Boolean(errors.email)}
						/>
						<ErrorMessage as="p" className="error" errors={errors} name="email" />
					</div>

					<div className={'field ' + (errors.password && 'invalid')}>
						<label htmlFor="password">New password</label>
						<input
							id="password"
							name="password"
							type="password"
							{...register('password', { required: true })}
							autoComplete="new-password"
							required
						/>
						<ErrorMessage as="p" className="error" errors={errors} name="password" />
					</div>

					<div className={'field ' + (errors.confirmPassword && 'invalid')}>
						<label htmlFor="confirmPassword">Confirm new password</label>
						<input
							id="confirmPassword"
							name="confirmPassword"
							type="password"
							{...register('confirmPassword', { required: true })}
							autoComplete="new-password"
							required
						/>
						<ErrorMessage as="p" className="error" errors={errors} name="confirmPassword" />
					</div>

					<div className="field">
						<button
							type="submit"
							className="chevron"
							disabled={isSubmitting}
						>
							Save New Password
						</button>
					</div>

				</form>

			</div>
		</div>
	);
}
