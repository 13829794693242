import { Helmet } from 'react-helmet-async';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';

import '@/css/index.css';
import styles from './Home.module.css';

function Home() {
	return (
		<div className={styles.homePage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § Home</title>
			</Helmet>
			<header>
				ActivKidz Admin v1
			</header>
			<AdminSideNav />
			<main>
				<header>
					<h1>Home</h1>
				</header>
			</main>
		</div>
	);
}

export default Home;
