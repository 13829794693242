import { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import AdminSideNav from '@/components/AdminSideNav/AdminSideNav';


import { titleCase } from 'title-case';

import api from '@shared/api/api';
import { formatDate } from '@shared/lib/formatters';

import '@/css/index.css';
import styles from './ViewParent.module.css';

function ViewParent() {
	const [parent, setParent] = useState();

	const params = useParams();

	useEffect(() => {
		async function fetchParent() {
			try {
				const response = await api.get(`/backoffice/parents/${params.parentId}`);
				const json = await response.json();
				setParent(json);
			} catch (error) {
				console.error(error);
				setParent();
			}
		}

		fetchParent();
	}, [params.parentId]);

	return (
		<div className={styles.viewParentPage + ' normalPage'}>
			<Helmet>
				<title>ActivKidz Admin § Parents § {parent ? parent.firstName + ' ' + parent.lastName : 'Loading…'}</title>
			</Helmet>

			<header>
				ActivKidz Admin
			</header>

			<AdminSideNav />

			<main>
				<header>
					<h1>{parent ? parent.firstName + ' ' + parent.lastName : ''}</h1>
				</header>
				<div className="card">
					{parent ? (
						<table>
							<tbody>
								<tr>
									<th scope="row">Name</th>
									<td>{parent.firstName + ' ' + parent.lastName}</td>
								</tr>
								<tr>
									<th scope="row">Email</th>
									<td>{parent.user.email}</td>
								</tr>
								<tr>
									<th scope="row">Mobile</th>
									<td>{parent.mobile}</td>
								</tr>
								<tr>
									<th scope="row">City</th>
									<td>{parent.city}</td>
								</tr>
								<tr>
									<th scope="row">Country</th>
									<td>{parent.country}</td>
								</tr>
								<tr>
									<th scope="row">Signed up</th>
									<td>{formatDate(parent.created, { includeYear: true })}</td>
								</tr>
								<tr>
									<th scope="row">Status</th>
									<td><span className={`statusChip ${parent.user.status}`}>{titleCase(parent.user.status)}</span></td>
								</tr>
							</tbody>
						</table>
					) : (
						<p className="loading">
							Loading… <img src="/images/spinner-dark.svg" alt="" />
						</p>
					)}
				</div>
			</main>
		</div>
	);
}

export default ViewParent;
