import { useState } from 'react';
import { TableSortLabel } from '@mui/material';

export default function SortLabel({ onSortAsc, onSortDesc, children }) {
	const [ASC, DESC] = ['asc', 'desc'];
	const [direction, setDirection] = useState(ASC);
	const handleSort = () => {
		const tmp = direction === ASC ? DESC : ASC;
		setDirection(tmp);
		switch (tmp) {
			case ASC:
				onSortAsc();
				break;
			case DESC:
				onSortDesc();
				break;
			default:
				break;
		}
	};
	return (
		<TableSortLabel onClick={handleSort} direction={direction}>
			{children}
		</TableSortLabel>
	);
}
