import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@shared/components/AuthProvider/AuthProvider';

export default function SignOut() {
	const auth = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		auth.signOut();
		navigate('/sign-in');
	});

	return null;
}
